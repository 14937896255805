import styled from 'styled-components';

export const DetailsLinkContainer = styled.div`
    display: flex;
    flex-direction: column;

    & a{
        margin: 0.5rem 0;
    }
`;
export const DetailsBtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;