import { UVinfo } from "../ts/customTypes"

export const UvInfos: { [key: string]: UVinfo } = {
    lowUV: {
        color: '#95C100',
        numColor: '#C8DF8F',
        risk: 'NIEDRIG',
        subtitle: 'Schutz nicht nötig.',
        information: 'Sie können sich bedenkenlos draußen aufhalten.',
    },
    midUV: {
        color: '#FFE600',
        numColor: '#FFF5AC',
        risk: 'MITTEL',
        subtitle: 'Schutz nötig.',
        information:
            'Mittags Schatten suchen. Angemessene Kleidung sowie Hut und Sonnenbrille tragen. Sonnenschutzmittel verwenden.',
    },
    highUV: {
        color: '#EE7539',
        numColor: '#F6B18B',
        risk: 'HOCH',
        subtitle: 'Schutz nötig.',
        information:
            'Mittags Schatten suchen. Angemessene Kleidung sowie Hut und Sonnenbrille tragen. Sonnenschutzmittel verwenden.',
    },
    veryHighUV: {
        color: '#FF263E',
        numColor: '#FC7685',
        risk: 'SEHR HOCH',
        subtitle: 'Schutz absolut notwendig.',
        information:
            'Mittags möglichst nicht draußen sein. Im Schatten bleiben! Angemessene Kleidung sowie Hut, Sonnenbrille und Sonnenschutzmittel sind ein Muss.',
    },
    extremeUV: {
        color: '#7D55C7',
        numColor: '#CE99E7',
        risk: 'EXTREM',
        subtitle: 'Schutz absolut notwendig.',
        information:
            'Mittags möglichst nicht draußen sein. Im Schatten bleiben! Angemessene Kleidung sowie Hut, Sonnenbrille und Sonnenschutzmittel sind ein Muss.',
    }
};