import styled from 'styled-components';
import { H1, H2, MaxWidth } from './generalStyles';

export const LandingpageContainer = styled.div`
    position: fixed;
    background-color: #000;
    width: 100%;
    max-width: ${MaxWidth};
    height: 100%;
    padding-top: 9%;
    top: 0;
`;
export const LandingpageTextContainer = styled.div`
    margin: 5% 10%;
    width: 80%;
`;
export const LandingpageH1 = styled(H1)`
    margin: 0;
    padding: 0;
    margin-top: -5px;
`;
export const LandingpageH1Margin = styled(LandingpageH1)`
    margin-bottom: 10px;
    `;
export const LandingpageTitle = styled.div`
    width: 100%;
    margin-bottom: 0;
`;
export const LandingpageSubtitle = styled(H2)`
    letter-spacing: 0;
    margin-top: 0;
    font-weight: normal;
`;
