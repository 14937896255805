import Instagram from '../../assets/icons/instagram.png';
import Facebook from '../../assets/icons/facebook.png';
import { IconLinkImg, IconLink } from '../../assets/styles/menuStyles';

export function IconLinks({socialMedia}:{socialMedia: 'Instagram' |  'Facebook'}) {
    if (socialMedia === 'Instagram') {
        return (
            <IconLink href='https://www.instagram.com/hautkrebspraevention_adp/'>
                <IconLinkImg src={Instagram} />
            </IconLink>
        );
    }
    else if (socialMedia === 'Facebook') {
        return (
            <IconLink href='https://www.facebook.com/adp.hautkrebspraevention'>
                <IconLinkImg src={Facebook} />
            </IconLink>
        );
    } else {
        return <></>;
    }
};
