import styled from "styled-components";
import { Paragraph, CenterContainer } from "./generalStyles";

export const ParagraphExtraFlag = styled(Paragraph)`
    margin: 0;
    margin-top: 1rem;
    width: 277px;
`;
export const ParagraphExtraFlag2 = styled(Paragraph)`
    margin-top: 0;
    width: fit-content;
`;
export const CenterContainerEva = styled(CenterContainer)`
    margin-top: 10%;
    display: flex;
    justify-content: center;
`;
export const EvaLink = styled.a`
    position: relative;
    z-index: 2;
    background-color: #000;
    color: #fff;
    padding: 0.5rem 0.75rem;
    border: 1px solid #fff;
    cursor: pointer;
    white-space: break-spaces;
    text-decoration: none;
`;