import styled from 'styled-components';
import { MaxWidth} from './generalStyles';

interface SunProps {
    isOnLandingPage?: boolean;
    bottom?: string;
}

export const SunImg = styled.img<SunProps>`
    max-width: 300px;
    width: ${props => props.isOnLandingPage ? '70%' : '50%'};
    margin: 7% 0;
    opacity: ${props => props.isOnLandingPage ? 1 : 0.5};
`;
export const SunImgBG = styled(SunImg)<SunProps>`
    width: 40%;
    margin: 0 30%;
    position: absolute;
    z-index: -1;
    bottom: ${props => props.bottom ? props.bottom : ''};
`;
export const SunContainerBG = styled.div`
    width: 100%;
    max-width: ${MaxWidth};
    position: fixed;
    z-index: 0;
    height: 100%;
    pointer-events: none;
`;
