import { FederalState, LocationNames } from './customTypes';

export const defaultLocation = {
    name: LocationNames.LIVE,
    lat: 54.2,
    long: 9.0,
};

export const federalStates: FederalState[] = [
    {
        name: 'Bayern',
        locations: [
            {
                name: LocationNames.ALTOETTING,
                lat: 48.1328716,
                long: 12.4036422,
            },
            {
                name: LocationNames.AMPFING,
                lat: 48.3106205,
                long: 12.4468313,
            },
            {
                name: LocationNames.BADENDORF,
                lat: 47.900457,
                long: 12.301369,
            },
            {
                name: LocationNames.BADEPLATZ_TINNINGER_SEE,
                lat: 47.824922,
                long: 12.210254,
            },
            {
                name: LocationNames.BADESEE_THANSAU,
                lat: 47.825718,
                long: 12.148477,
            },
            {
                name: LocationNames.BURGHAUSEN,
                lat: 48.158543,
                long: 12.828545,
            },
            {
                name: LocationNames.GRUND_MITTELSCHULE_BUCHBACH,
                lat: 48.3129,
                long: 12.2776,
            },
            {
                name: LocationNames.CHIEMING,
                lat: 47.894398,
                long: 12.536508,
            },
            {
                name: LocationNames.FREIBAD_NUSSDORF,
                lat: 47.734245,
                long: 12.157001,
            },
            {
                name: LocationNames.GESUNDHEITSAMT_ROSENHEIM,
                lat: 47.85659,
                long: 12.12273,
            },
            {
                name: LocationNames.GRUNDSCHULE_KIEFERSFELDEN,
                lat: 47.612141,
                long: 11.0825,
            },
            {
                name: LocationNames.GRUNDSCHULE_NUSSDORF,
                lat: 47.742621,
                long: 12.15298,
            },
            {
                name: LocationNames.HELDENSTEIN,
                lat: 48.1426077,
                long: 12.2149748,
            },
            {
                name: LocationNames.INGOLSTADT,
                lat: 48.4559526,
                long: 11.2532714,
            },
            {
                name: LocationNames.KRAIBURG,
                lat: 48.187342,
                long: 12.434538,
            },
            {
                name: LocationNames.LANDSBERG_AM_LECH,
                lat: 48.0302819,
                long: 10.5213264,
            },
            {
                name: LocationNames.MARKTL,
                lat: 48.1559,
                long: 12.4947,
            },
            {
                name: LocationNames.MUEHLDORF,
                lat: 48.240967,
                long: 12.524745,
            },
            {
                name: LocationNames.NEUMARKTSTVEIT,
                lat: 48.254091,
                long: 12.413184,
            },
            {
                name: LocationNames.PERACH,
                lat: 48.256339,
                long: 12.757284,
            },
            {
                name: LocationNames.ROSENHEIM,
                lat: 47.512556,
                long: 12.070516,
            },
            {
                name: LocationNames.SEEONSEEBRUCK,
                lat: 47.932228,
                long: 12.479489,
            },
            {
                name: LocationNames.STAATLICHE_REALSCHULE_BRUCKMUEHL,
                lat: 47.8837,
                long: 11.91808,
            },
            {
                name: LocationNames.STAATLICHE_REALSCHULE_WASSERBURG,
                lat: 48.06478,
                long: 12.23272,
            },
            {
                name: LocationNames.STRANDBAD_BERNAU_FELDEN,
                lat: 47.832745,
                long: 12.379481,
            },
            {
                name: LocationNames.SPORTPLATZ_HOELZL,
                lat: 47.795778,
                long: 12.162848,
            },
            {
                name: LocationNames.TRAUNREUT,
                lat: 47.961764,
                long: 12.58798,
            },
            {
                name: LocationNames.TRAUNSTEIN,
                lat: 47.869561,
                long: 12.648437,
            },
            {
                name: LocationNames.TOURIST_INFO_KIEFERSFELDEN,
                lat: 47.611248,
                long: 12.18951,
            },
            {
                name: LocationNames.UEBERSEE,
                lat: 47.8424,
                long: 12.47751,
            },
            {
                name: LocationNames.WIRTSCHAFTSSCHULE_BAD_AIBLING,
                lat: 47.86597,
                long: 11.99721,
            },
        ],
    },
    {
        name: 'Niedersachsen',
        locations: [
            {
                name: LocationNames.CUXHAVEN_ALTENBRUCH,
                lat: 53.4922,
                long: 8.4549,
            },
            {
                name: LocationNames.CUXHAVEN_GRIMMERSHORNBUCHT,
                lat: 53.5256,
                long: 8.4121,
            },
        ],
    },
    {
        name: 'Schleswig-Holstein',
        locations: [
            {
                name: LocationNames.DAHME,
                lat: 54.1323,
                long: 11.0516,
            },
            {
                name: LocationNames.ECKERNFOERDE,
                lat: 54.46617,
                long: 9.8409,
            },
            {
                name: LocationNames.FEHMARN,
                lat: 54.40893,
                long: 11.19911,
            },
            {
                name: LocationNames.GLUECKSBURG,
                lat: 54.84135,
                long: 9.53053,
            },
            {
                name: LocationNames.GROEMITZ,
                lat: 54.14402,
                long: 10.96206,
            },
            {
                name: LocationNames.GRUBE,
                lat: 54.26536,
                long: 11.0825,
            },
            {
                name: LocationNames.HOERNUM,
                lat: 54.75709,
                long: 8.2803,
            },
            {
                name: LocationNames.KELLENHUSEN,
                lat: 54.18734,
                long: 11.06105,
            },
            {
                name: LocationNames.LENSAHN,
                lat: 54.21272,
                long: 10.87539,
            },
            {
                name: LocationNames.LIST,
                lat: 55.01595,
                long: 8.43745,
            },
            {
                name: LocationNames.LUEBECK,
                lat: 53.525406,
                long: 10.40105,
            },
            {
                name: LocationNames.NEUSTADT,
                lat: 54.09348,
                long: 10.81465,
            },
            {
                name: LocationNames.SCHWEDENECK,
                lat: 54.48427,
                long: 10.12349,
            },
            {
                name: LocationNames.STRANDE,
                lat: 54.43719,
                long: 10.17502,
            },
            {
                name: LocationNames.SUEDERLUEGUM,
                lat: 54.5222861,
                long: 8.5351062,
            },
            {
                name: LocationNames.SYLT,
                lat: 54.90833,
                long: 8.29784,
            },
            {
                name: LocationNames.TIMMENDORF,
                lat: 53.99272,
                long: 10.83007,
            },
            {
                name: LocationNames.WENNINGSTEDT_BRADERUP,
                lat: 54.93677,
                long: 8.31491,
            },
        ],
    },
];
