import styled from 'styled-components';
import { Paragraph } from './generalStyles';

export const Icon = styled.img`
    width: 30%;
    margin: 1.5rem 0;
`;
export const UvIconContainer = styled.div`
    position: relative;
    z-index: 1;
`;
export const ParagraphUvHead = styled(Paragraph)`
    width: 75%;
`;
export const ParagraphUvEffect = styled(Paragraph)`
    white-space: normal;
`;
