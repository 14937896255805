import { Sponsor } from './customTypes';

import aeronautecLogo from '../sponsorsLogos/aeronautec.png';
import dlrgLogo from '../sponsorsLogos/dlrg.png';
import gluecksburgLogo from '../sponsorsLogos/gluecksburg.png';
import groemitzLogo from '../sponsorsLogos/groemitz.png';
import kellenhusenLogo from '../sponsorsLogos/kellenhusen.png';
import kupferschmiedeLogo from '../sponsorsLogos/kupferschmiede.png';
import neustadtLogo from '../sponsorsLogos/neustadt.png';
import parkerLogo from '../sponsorsLogos/parker.png';
import syltLogo from '../sponsorsLogos/sylt.png';
import chiemgauLogo from '../sponsorsLogos/chiemgauTherme.png';
import dorfwirtLogo from '../sponsorsLogos/derDorfwirt.png';
import aokLoko from '../sponsorsLogos/aok.png';
import gesundheitsregionLogo from '../sponsorsLogos/gesundheitsregion.png';
import altoettingLogo from '../sponsorsLogos/altoetting.png';
import tusLogo from '../sponsorsLogos/tus.png';
import dlrgSuederluegumLogo from '../sponsorsLogos/dlrgSuederluegum.png';
import landsbergAmLechLogo from '../sponsorsLogos/landsbergAmLechLogo.png';
import ingolstadtLogo from '../sponsorsLogos/ingolstadtLogo.png';
import rosenheimLogo from '../sponsorsLogos/rosenheimLogo.png';
import brkWasserwachtLogo from '../sponsorsLogos/brkWasserwacht.png';
import buchbachLogo from '../sponsorsLogos/buchbachLogo.svg';

export const sponsors: Sponsor[] = [
    {
        name: 'aeronautec',
        logo: aeronautecLogo,
        link: 'https://www.aeronautec.net/',
    },
    {
        name: 'Campingplatz Kupferschmiede',
        logo: kupferschmiedeLogo,
        link: 'https://www.campingkupferschmiede.de/',
    },
    {
        name: 'Parker Outdoor',
        logo: parkerLogo,
        link: 'https://parkeroutdoor.com/',
    },
    {
        name: 'Insel Sylt Tourismus-Service',
        logo: syltLogo,
        link: 'https://www.insel-sylt.de/',
    },
    {
        name: 'Tourismus-Service Ostseebad Kellenhusen',
        logo: kellenhusenLogo,
        link: 'https://www.kellenhusen.de/',
    },
    {
        name: 'Tourismus-Service Neustadt-Pelzerhaken-Rettin ',
        logo: neustadtLogo,
        link: 'https://www.stadt-neustadt.de/',
    },
    {
        name: 'Deutsche Lebens-Rettungs-Gesellschaft',
        logo: dlrgLogo,
        link: 'https://www.dlrg.de/',
    },
    {
        name: 'Der Dorfwirt St. Georgen',
        logo: dorfwirtLogo,
        link: 'https://www.derdorfwirt.eu/',
    },
    {
        name: 'Tourist-Information Glücksburg',
        logo: gluecksburgLogo,
        link: 'https://www.gluecksburg-urlaub.de/',
    },
    {
        name: 'Grömitz - Ostseebad der Sonnenseite',
        logo: groemitzLogo,
        link: 'https://www.groemitz.de/',
    },
    {
        name: 'Chiemgau Thermen Bad Endorf',
        logo: chiemgauLogo,
        link: 'https://www.chiemgau-thermen.de/',
    },
    {
        name: 'AOK',
        logo: aokLoko,
        link: 'https://www.aok.de',
    },
    {
        name: 'GesundheitsregionPlus Mühldorf a. Inn',
        logo: gesundheitsregionLogo,
        link: 'https://www.lra-mue.de/gesundheit-tiere-lebensmittel/gesundheit/gesundheitsregion-plus',
    },
    {
        name: 'Landratsamt Altötting',
        logo: altoettingLogo,
        link: 'https://www.lra-aoe.de/',
    },
    {
        name: 'TuS Traunreut',
        logo: tusLogo,
        link: 'https://www.tustraunreut.de/',
    },
    {
        name: 'DLRG Süderlügum',
        logo: dlrgSuederluegumLogo,
        link: 'https://amt-suederluegum.dlrg.de',
    },
    {
        name: 'Gesundheitsamt Ingolstadt',
        logo: ingolstadtLogo,
        link: 'https://www.ingolstadt.de/gesundheitsregionplus',
    },
    {
        name: 'Landsberg am Lech',
        logo: landsbergAmLechLogo,
        link: 'https://www.landkreis-landsberg.de/soziales-gesundheit/gesundheitsamt/?url=%2FGesundheit-Soziales%2FGesundheitsamt%2FGesundheitsamt.aspx%3Fview%3D~%2Fkxp%2Forgdata%2Fdefault%26orgid%3Dddde84d8-7027-498b-87a6-1ba5eb180135&cHash=8a157888fec4cc8390a3d435f480af1b#/',
    },
    {
        name: 'Gesundheitsamt Rosenheim',
        logo: rosenheimLogo,
        link: 'https://www.landkreis-rosenheim.de',
    },
    {
        name: 'Kreiswasserwacht Ingolstadt',
        logo: brkWasserwachtLogo,
        link: 'https://www.wasserwacht-ingolstadt.de/',
    },
    {
        name: 'Grund- und Mittelschule Buchbach',
        logo: buchbachLogo,
        link: 'https://www.schulebuchbach.de/',
    },
];