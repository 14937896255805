import styled from "styled-components";
import { Link, H2 } from './generalStyles';
export const IconLinkContainer = styled.div`
    display: grid;
    grid-template: "a b" 1fr / 1fr 1fr;
    text-align: center;
`;
export const IconLink = styled(Link)`
    margin: 0;
`;
export const IconLinkImg = styled.img`
    width: 70%;
`;
export const TopCover = styled.div`
    width: 100%;
    height: 15%;
    position: fixed;
    z-index: 10;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;
export const H2Menu = styled(H2)`
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 0;
`;
export const H2Menu2 = styled(H2)`
    font-size: 2.5rem;
    margin-top: 0;
`;
