import { createContext, useContext, useState } from 'react';
import { AnyJsx, Location } from '../../assets/ts/customTypes';
import { defaultLocation } from '../../assets/ts/locations';

export type LocationContextType = [Location, (location: Location) => void];

export const LocationContext = createContext<LocationContextType>(null as any);

export function useLocation() {
    const val = useContext(LocationContext);
    if (!val) {
        throw new Error('LocationContext not found');
    }

    return val;
}

export function LocationContextProvider(props: { children: AnyJsx }) {
    const state = useState(defaultLocation);
    return <LocationContext.Provider value={state}>{props.children}</LocationContext.Provider>;
}
