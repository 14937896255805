import { TitleContainer, H1, ContentContainer, Paragraph, Link } from "../assets/styles/generalStyles";
import { SponsorsContainer } from "../assets/styles/sponsorsStyles";
import { ArrowToTop } from "../lib/components/ArrowToTop";
import { TheSun } from "../lib/components/TheSun";
import { sponsors } from "../assets/ts/sponsors";
import { SponsorLogo } from "../lib/components/SponsorLogo";
import { Sponsor } from "../assets/ts/customTypes";

export function PageSponsors() {
    return (
        <>
            <TheSun isInText={false} bottom='10%' />
            <ContentContainer>
                <TitleContainer>
                    <H1>
                        PARTNER &{'\n'}
                        SPONSOREN
                    </H1>
                </TitleContainer>
                <Paragraph>
                    Wir bedanken uns für die Unterstützung und Mitwirkung.
                </Paragraph>
                <SponsorsContainer>
                    {sponsors.map((sponsor) => (<SponsorLogo sponsor={sponsor} key={sponsor.name} />))}
                </SponsorsContainer>
            </ContentContainer>
            <ArrowToTop />
        </>
    );
}