import styled from 'styled-components';

export const SponsorsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px;
    position: relative;
    @media (min-width: 750px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const SponsorsLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.5rem;
`;

export const SponsorIcon = styled.img`
    max-width: 100%;
    height: 80px;
    filter: grayscale(100%);
    object-fit: contain;
    margin: 1em auto;
`;

export const SponsorLink = styled.a`
    display: contents;
    text-decoration: none;
    font-size: 0.7rem;
    @media (min-width: 750px) {
        font-size: 1rem
    }
`;