import styled from "styled-components";

export const OList = styled.ol`
    position: relative;
    z-index: 1;
    padding-left: 20px;
`;

export const ListItem = styled.li`
    line-height: 1.1;
    margin: 1rem 0;
`;
