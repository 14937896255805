import Sun from '../../assets/img/sun-q.png';
import { SunImg, SunContainerBG, SunImgBG } from '../../assets/styles/theSunStyles';
import { CenterContainer } from '../../assets/styles/generalStyles';

export function TheSun({ isInText, bottom, onLandingpage }: { isInText: boolean; bottom?: string; onLandingpage?: boolean }) {
    if (isInText) {
        return (
            <CenterContainer>
                <SunImg src={Sun} isOnLandingPage={onLandingpage || false} />
            </CenterContainer>
        );
    } else {
        return (
            <SunContainerBG>
                <SunImgBG src={Sun} bottom={bottom} />
            </SunContainerBG>
        );
    }
};