import { ContentContainer, H1, Paragraph, TitleContainer } from "../assets/styles/generalStyles";
import {ListItem, OList} from "../assets/styles/tipsStyles";
import { ArrowToTop } from "../lib/components/ArrowToTop";
import { TheSun } from "../lib/components/TheSun";

export function PageTips(){
    return (
        <>
            <TheSun isInText={false} bottom='45%' />
            <ContentContainer>
                <TitleContainer>
                    <H1>
                        10 TIPPS ZUM{'\n'}
                        UV-SCHUTZ.
                    </H1>
                </TitleContainer>
                <Paragraph>
                    Kluger Sonnenschutz bedeutet mehr als nur Sonnencreme. Die Strandpause in der Mittagszeit, Schatten
                    und die richtige Kleidung sind die wichtigsten Schutzstrategien. Hier sind unsere Tipps:
                </Paragraph>
                <OList>
                    <ListItem>Säuglinge niemals der prallen Sonne aussetzen.</ListItem>
                    <ListItem>Sonne in der Mittagszeit (zwischen 11 und 15 Uhr) vermeiden.</ListItem>
                    <ListItem>Die Haut an die Sonne gewöhnen und jede Hautrötung vermeiden.</ListItem>
                    <ListItem>Kleidung, Hut und Sonnenbrille schützen am besten vor der Sonne.</ListItem>
                    <ListItem>
                        Vor dem Aufenthalt in der Sonne reichlich Sonnencreme auf nicht bedeckte Körperstellen auftragen
                        (mindestens Lichtschutzfaktor 30).
                    </ListItem>
                    <ListItem>Sonnencreme wiederholt auftragen, insbesondere nach dem Baden.</ListItem>
                    <ListItem>Wiederholtes Eincremen verlängert nicht die Sonnenschutzzeit.</ListItem>
                    <ListItem>Auch im Alltag (Beruf, Sport und Freizeit) auf Sonnenschutz achten.</ListItem>
                    <ListItem>In der Sonne auf Deodorants und Parfüms verzichten.</ListItem>
                    <ListItem>
                        Den UV-Index (UVI) nutzen, um die Stärke der UV-Strahlung der Sonne besser einzuschätzen.
                    </ListItem>
                </OList>
                <Paragraph>
                    ACHTUNG: Sonnencreme hilft dabei, schmerzhaften Sonnenbrand zu vermeiden. Sie schützt jedoch nicht
                    vor Hautkrebs. Denn schon bevor es zu einer Hautrötung kommt, kann die Haut massiven und
                    langfristigen Schaden nehmen.
                </Paragraph>
                <ArrowToTop />
            </ContentContainer>
        </>
    );
}