import { UVinfo } from '../assets/ts/customTypes';
import {
    UvContainer,
    UvI,
    UvInfo,
    UvNumber,
    UvRisk,
    UvSubt,
    UvTextContainer,
    UvIconContainer,
    UvCenterContainer,
    UvLocation, UvLocationContainer, UvBtn,
    HiddenBtnContainer,
    HiddenBtn
} from '../assets/styles/uvPageStyles';
import { UvInfos } from '../assets/styles/uvInfosStyles'
import house from '../assets/icons/house_s.png';
import parasol from '../assets/icons/parasol_s.png';
import clothes from '../assets/icons/clothes_s.png';
import sunscrean from '../assets/icons/creme_s.png';
import No1 from '../assets/numbers/no-01.svg';
import No2 from '../assets/numbers/no-02.svg';
import No3 from '../assets/numbers/no-03.svg';
import No4 from '../assets/numbers/no-04.svg';
import No5 from '../assets/numbers/no-05.svg';
import No6 from '../assets/numbers/no-06.svg';
import No7 from '../assets/numbers/no-07.svg';
import No8 from '../assets/numbers/no-08.svg';
import No9 from '../assets/numbers/no-09.svg';
import No10 from '../assets/numbers/no-10.svg';
import No11 from '../assets/numbers/no-11.svg';
import { useLocation } from '../lib/context/LocationContext';
import { PageNames } from '../assets/ts/customTypes';

export function determineRisk(uvIndex: number): UVinfo {
    if (uvIndex < 3) {
        return UvInfos['lowUV'];
    } else if (uvIndex < 6) {
        return UvInfos['midUV'];
    } else if (uvIndex < 8) {
        return UvInfos['highUV'];
    } else if (uvIndex < 11) {
        return UvInfos['veryHighUV'];
    } else {
        return UvInfos['extremeUV'];
    }
}

function determineImg(uvIndex: number) {
    switch (uvIndex) {
        case 1:
            return No1;
        case 2:
            return No2;
        case 3:
            return No3;
        case 4:
            return No4;
        case 5:
            return No5;
        case 6:
            return No6;
        case 7:
            return No7;
        case 8:
            return No8;
        case 9:
            return No9;
        case 10:
            return No10;
        case 11:
            return No11;
        default:
            return '0';
    }
}

export function UVpage({
    uvIndex,
    uvAtLocation,
    setNextPage,
    showCoords,
}: {
    uvIndex: number;
    uvAtLocation: number;
    setNextPage: (nextPage: PageNames) => void;
    showCoords: () => void;
}) {
    const currentUV = determineRisk(uvIndex);
    const numImg = determineImg(uvIndex);
    const [location] = useLocation();

    const handleClick = (nextPage: PageNames) => {
        if (nextPage !== PageNames.INDEX) {
            window.scrollTo({ top: 0 });
        }
        setNextPage(nextPage);
    };

    return (
        <UvContainer bgColor={currentUV.color} id={'UV-' + uvIndex}>
            <UvNumber src={numImg} uvIndex={uvIndex} />
            <UvCenterContainer>
                <UvTextContainer>
                    {uvIndex === uvAtLocation && (
                            <UvI>
                                HEUTIGER{'\n'}
                                Tagesspitzenwert
                            </UvI>
                    )}
                    <UvRisk>{currentUV.risk}</UvRisk>
                    <UvSubt>{currentUV.subtitle}</UvSubt>
                    <UvInfo>{currentUV.information}</UvInfo>
                </UvTextContainer>
                {uvIndex > 2 && (
                    <UvIconContainer>
                        {uvIndex >= 8 && <img alt='Ein Haus' src={house} />}
                        <img alt='Ein Sonnenschirm' src={parasol} />
                        <img alt='Kleidung' src={clothes} />
                        <img alt='Sonnencreme' src={sunscrean} />
                    </UvIconContainer>
                )}
            </UvCenterContainer>
            <UvLocationContainer>
                <UvLocation onClick={() => showCoords()}>{location.name}</UvLocation>
                <UvBtn onClick={() => handleClick(PageNames.LOCATIONS)}>Mehr Orte</UvBtn>
            </UvLocationContainer>
        </UvContainer>
    );
}