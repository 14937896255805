import { IconLinkContainer,H2Menu, H2Menu2 } from '../../assets/styles/menuStyles';
import { Link } from '../../assets/styles/generalStyles';
import {slide as BurgerMenu, State} from 'react-burger-menu';
import { IconLinks } from './IconLinks';
import { useState } from 'react';
import BurgerIcon from '../../assets/icons/burger-menu.svg';
import CrossIcon from '../../assets/icons/arrow-right.svg';
import { PageNames } from '../../assets/ts/customTypes';

export function Menu({
    setNextPage,
}: {
    setNextPage: (nextPage: PageNames) => void;
    }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClick = (nextPage: PageNames) => {
        if (nextPage !== PageNames.INDEX) {
            window.scrollTo({ top: 0 });
        }
        setIsMenuOpen(false);
        setNextPage(nextPage);
    }
    const handleStateChange = (state: State) => {
        setIsMenuOpen(state.isOpen);
    };
    return (
        <BurgerMenu
            customBurgerIcon={<img src={BurgerIcon} alt='Open Menu' />}
            customCrossIcon={<img src={CrossIcon} alt='Close Menu' />}
            isOpen={isMenuOpen}
            onStateChange={handleStateChange}
            right={true}
            width={'75%'}
        >
            <H2Menu>MEHR</H2Menu>
            <H2Menu2>ERFAHREN:</H2Menu2>
            <Link onClick={() => handleClick(PageNames.INDEX)}>UV-Index: Tagesspitzenwert</Link>
            <Link onClick={() => handleClick(PageNames.DETAILS)}>UV-Index: Aktueller Wert</Link>
            <Link onClick={() => handleClick(PageNames.LOCATIONS)}>Regionen und Orte</Link>
            <Link onClick={() => handleClick(PageNames.CAMPAIGN)}>Die Kampagne</Link>
            <Link onClick={() => handleClick(PageNames.TAN)}>Wissenswertes</Link>
            <Link onClick={() => handleClick(PageNames.TIPS)}>Richtiger UV-Schutz</Link>
            <Link onClick={() => handleClick(PageNames.SPONSORS)}>Partner & Sponsoren</Link>
            <Link onClick={() => handleClick(PageNames.IMPRINT)}>Impressum & Datenschutz</Link>
            <div>
                <IconLinkContainer>
                    <IconLinks socialMedia='Instagram' />
                    <IconLinks socialMedia='Facebook' />
                </IconLinkContainer>
            </div>
        </BurgerMenu>
    );
}