import { Icon, UvIconContainer, ParagraphUvEffect, ParagraphUvHead } from '../../assets/styles/uvIconStyles';
import { CenterContainer } from '../../assets/styles/generalStyles';

export function UvIcon({ src, headline, text }: { src: string; headline: string; text: string }) {
    return (
        <UvIconContainer>
            <CenterContainer>
                <Icon src={src} alt='' />
            </CenterContainer>
            <ParagraphUvHead>{headline}</ParagraphUvHead>
            <ParagraphUvEffect>{text}</ParagraphUvEffect>
        </UvIconContainer>
    );
}