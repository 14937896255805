import { ContentContainer, H1, Paragraph, TitleContainer } from "../assets/styles/generalStyles";
import { ArrowToTop } from "../lib/components/ArrowToTop";
import { TheSun } from "../lib/components/TheSun";
import { UvIcon } from '../lib/components/UvIcon';
import Aging from '../assets/icons/aging.png';
import Cancer from '../assets/icons/cancer.png';
import Eyes from '../assets/icons/eyes.png';
import ImmuneSystem from '../assets/icons/immune-system.png';
import Skin from '../assets/icons/skin.png';

export function PageHealthyTan() {
    return (
        <>
            <TheSun isInText={false} bottom='5%' />
            <ContentContainer>
                <TitleContainer>
                    <H1>
                        GESUNDE{'\n'}
                        BRÄUNE GIBT{'\n'}
                        ES NICHT{'\n'}
                    </H1>
                </TitleContainer>
                <Paragraph>
                    Wenn UV-Strahlung auf die Haut trifft, schädigt sie in Sekundenschnelle das Erbgut (DNA) der
                    Hautzellen. Dies gilt sowohl für die natürliche UV-Strahlung der Sonne als auch für die künstlich
                    erzeugte UV-Strahlung in Solarien.
                </Paragraph>
                <Paragraph>
                    Die UV-Strahlung bringt einen ausgeklügelten Schutzmechanismus in Gang. Der Körper bildet das dunkle
                    Farbpigment Melanin, das sich wie ein Sonnenschirm über die Zellkerne legt. So schützt das Melanin
                    in gewissem Umfang das Erbgut vor weiterer Schädigung durch die krebserregende UV-Strahlung. Die
                    gebräunte Haut ist nur ein sichtbarer Nebeneffekt dieses „intrazellulären” Kraftakts.
                </Paragraph>
                <Paragraph>
                    Tatsächlich zeigt die Pigmentierung (= Bräunung) eine hohe UV-Belastung der Haut an. Die Bräunung
                    könnte man als körpereigenen Schutz bezeichnen – allerdings maximal mit einem Lichtschutzfaktor von
                    4. Damit ist die Haut gegenüber UV-Strahlung kaum gewappnet. Es kommt weiterhin zu DNA-Schäden in
                    den Hautzellen, womit das Risiko steigt, an Hautkrebs zu erkranken.
                </Paragraph>
                <Paragraph>
                    Eine gesunde Bräune gibt es also nicht, denn Bräunung ist immer eine Reaktion auf bereits erfolgte
                    Schädigung durch UV-Strahlen. Außerdem stellt sie immer nur einen geringen Schutz der Haut vor
                    dieser krebserregenden Strahlung dar. Deswegen sollte Hautbräunung weder unter natürlicher Sonne,
                    noch im Solarium gezielt herbeigeführt werden.
                </Paragraph>

                <H1>WIRKUNGEN VON{'\n'}UV-STRAHLUNG</H1>
                <Paragraph>
                    Eine Hautrötung, ein Gefühl von „platt sein“ - Die UV-Strahlung der Sonne wirkt sich auf
                    verschiedene Weise auf den Körper aus. Wichtig zu wissen ist, dass sie auch in der langen Frist
                    gesundheitsschädlich ist.
                </Paragraph>
                <Paragraph>
                    Die einzige positive Auswirkung: Bildung von Vitamin D. Denn wenn UV-Strahlen auf die Haut treffen,
                    regt die kurzwellige UV-B-Strahlung die körpereigene Vitamin D-Bildung an. Diese ist zeitlich
                    begrenzt. Daher reicht es, Gesicht, Hände und Arme 2 bis 3 Mal pro Woche für 10 bis 15 Minuten der
                    Sonne auszusetzen.
                </Paragraph>
                <Paragraph>Der einzigen positiven Auswirkung stehen mehrere negative Auswirkungen gegenüber:</Paragraph>
                <UvIcon
                    src={ImmuneSystem}
                    headline='SCHWÄCHUNG DES IMMUNSYSTEMS:'
                    text='Wenn UV-Strahlung auf die Haut trifft, schwächt es das Immunsystem. Die Reaktion auf fremde
                    Organismen wie Bakterien oder Viren wird nicht nur durch bestimmte Erkrankungen oder Stress, sondern
                    auch durch das Einwirken von UV-Strahlen unterdrückt oder verlangsamt – genannt wird dies
                    Immunsuppression.'
                />
                <UvIcon
                    src={Skin}
                    headline='SCHÄDIGUNG DER HAUT:'
                    text='Wenn UV-Strahlung auf die Haut trifft,
                    schädigt dies in Sekundenschnelle
                    das Erbgut (DNA) der Hautzellen.
                    Der Körper kann diese Schäden
                    meistens beheben – funktioniert diese
                    körpereigene Reparatur nicht richtig,
                    entsteht langfristig Hautkrebs. Als Reaktion
                    auf die massive Zellschädigung kommt es zu
                    einer entzündlichen Hautrötung,
                    dem sogenannten Sonnenbrand.'
                />
                <UvIcon
                    src={Eyes}
                    headline='SCHÄDIGUNG DER AUGEN:'
                    text='Akute Schädigungen der Augen durch
                    UV-Strahlung sind schmerzhafte Binde-
                    und Hornhautentzündungen.
                    Die Strahlen zerstören die äußersten
                    Zellen der Hornhaut und der Bindehaut.
                    Dies macht sich sechs bis acht Stunden
                    nach der UV-Bestrahlung durch
                    starke Augenschmerzen bemerkbar.
                    Langfristig kann UV-Strahlung
                    schwerwiegende Augenerkrankungen
                    wie z. B. den Grauen Star auslösen.'
                />
                <UvIcon
                    src={Aging}
                    headline='VORZEITIGE HAUTALTERUNG:'
                    text='Die in der UV-Strahlung enthaltene
                    UV-A-Strahlung dringt tief in die Haut
                    ein und zerstört dort die elastischen /
                    collagenen Fasern. Die Haut verliert
                    Ihre Spannkraft und Falten entstehen.'
                />
                <UvIcon
                    src={Cancer}
                    headline='HAUTKREBS:'
                    text='UV-Strahlung schädigt das Erbgut
                    (DNA) von Hautzellen, auch ohne
                    Sonnenbrand. Dies gilt für die UV-
                    A- und für die UV-B-Strahlung. Zellen
                    mit geschädigtem Erbgut können im
                    Laufe der Zeit zu Krebszellen entarten
                    – Hautkrebs entsteht. Je öfter man
                    sich also UV-Strahlung aussetzt,
                    umso häufiger können Zellen mit
                    geschädigtem Erbgut entstehen. So
                    erhöht sich die Wahrscheinlichkeit im
                    Laufe des Lebens an Hautkrebs zu
                    erkranken.'
                />
                <Paragraph>Grafiken: Frank de Buhr</Paragraph>
                <ArrowToTop />
            </ContentContainer>
        </>
    );
}