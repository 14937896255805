import { ContentContainer, H1, Paragraph } from '../assets/styles/generalStyles';
import { ArrowToTop } from '../lib/components/ArrowToTop';
import { TheSun } from "../lib/components/TheSun";

const fontsLink = (
    <a href='https://www.fonts.com/' target='_blank' rel='noreferrer'>
        fonts.com
    </a>
);
const privacyLink = <a href='https://www.fonts.com/' target='_blank' rel='noreferrer'>Datenschutzrichtlinien</a>;

export function PageImprint() {
    return (
        <>
            <TheSun isInText={false} bottom='5%' />
            <ContentContainer>
                <H1>IMPRESSUM UND{'\n'}DATENSCHUTZ.</H1>
                <Paragraph>
                    Stiftung Deutsche Krebshilfe {'\n'}
                    Buschstr. 32 {'\n'}
                    53113 Bonn {'\n'}
                    Telefon: +49 228 7 29 90-0 {'\n'}
                    Telefax: +49 228 7 29 90-11 {'\n'}
                    deutsche@krebshilfe.de {'\n'}
                    www.krebshilfe.de {'\n'}
                </Paragraph>
                <Paragraph>
                    Arbeitsgemeinschaft Dermatologische {'\n'}
                    Prävention (ADP) e. V. {'\n'}
                    c/o Prof. Dr. Breitbart {'\n'}
                    Am Krankenhaus 1a {'\n'}
                    21614 Buxtehude {'\n'}
                    +49 40 20 913-160 {'\n'}
                    +49 40 20 913-161 {'\n'}
                    <a href='mailto:info@adpev.de'>info@adpev.de</a> {'\n'}
                    <a href='https://www.hautkrebspraevention.de' target='blank'>www.hautkrebspraevention.de</a> {'\n'}
                </Paragraph>
                <Paragraph>
                    Datenschutzhinweis: Die Abfrage der Standortdaten dient ausschließlich dem Zweck der Ermittlung des
                    UV-Indexes am Standort des Nutzers. Diese Standortdaten werden nicht gespeichert, sondern
                    anonymisiert zur Abfrage des UV-Indexes an die Schnittstelle des Deutschen Wetterdienstes (DWD)
                    weitergeleitet. Wir speichern zudem anonymisiert die Anzahl der Seitenaufrufe, wobei keine
                    personenbezogenen Daten oder Details über die Nutzung der Website erhoben werden.
                </Paragraph>
                <Paragraph>
                    Einbindung von Schriftarten von {fontsLink} (Monotype) {'\n'}
                    Unsere Website bindet Schriften des Anbieters Monotype Imaging Holdings Inc., Woburn, USA, unter der
                    Marke {fontsLink} ein. Beim Besuch unserer Website wird aus lizenzrechtlichen und
                    abrechnungstechnischen Gründen eine Verbindung mit Servern der Monotype Imaging Holdings Inc.
                    hergestellt und dabei Ihre IP-Adresse übermittelt. Die IP-Adresse wird vom Anbieter zur Speicherung
                    und weiterer Verarbeitung anonymisiert. Es werden also keine personenbezogenen Daten des
                    Website-Nutzers erhoben oder verarbeitet. Die Daten werden vom Anbieter für 30 Tage gespeichert und
                    nach Ablauf dieser Frist restlos gelöscht. Die Nutzung von Schriften von {fontsLink} erfolgt im
                    Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
                    Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Weitere Informationen finden sich in der
                    Datenschutzerklärung der Monotype Imaging Holdings Inc.:
                    {privacyLink}
                </Paragraph>
            </ContentContainer>
            <ArrowToTop />
        </>
    );
}
