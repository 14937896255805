import { SponsorIcon, SponsorLink, SponsorsLogoContainer } from '../../assets/styles/sponsorsStyles';
import { Sponsor } from '../../assets/ts/customTypes';

export function SponsorLogo({ sponsor }: {sponsor: Sponsor}) {
    return (
        <SponsorsLogoContainer>
            <SponsorLink href={sponsor.link}  target='blank'>
                <SponsorIcon src={sponsor.logo} alt={sponsor.name} />
                {sponsor.name}
            </SponsorLink>
        </SponsorsLogoContainer>
    );
}
