import { ContentContainer, Paragraph, TitleContainer, H1, LinkAsButton } from '../assets/styles/generalStyles';
import { ParagraphExtraFlag, ParagraphExtraFlag2, CenterContainerEva } from '../assets/styles/campaignStyles';
import { ArrowToTop } from "../lib/components/ArrowToTop";
import { TheSun } from "../lib/components/TheSun";

export function PageCampaign() {
    return (
        <>
            <TheSun isInText={false} bottom='25%' />
            <ContentContainer>
                <TitleContainer>
                    <H1>DIE{'\n'}KAMPAGNE</H1>
                </TitleContainer>
                <ParagraphExtraFlag>
                    Sport, Erholung und Abschalten: Aktivitäten im Freien tun Körper und Seele gut.
                </ParagraphExtraFlag>
                <ParagraphExtraFlag2>
                    Eine immer größer werdende Gefahr geht dabei ausgerechnet von der Sonne aus: So wunderbar sich Licht
                    und Wärme anfühlen, so gefährlich ist ihre UV-Strahlung. Denn: Jedes Ereignis mit viel Sonne lässt
                    ohne den richtigen Schutz auch langfristige Schäden in den Hautzellen zurück. Über die Jahre hinweg
                    kann so Hautkrebs entstehen. Durch den Klimawandel verstärkt sich dieses Problem voraussichtlich
                    noch.
                </ParagraphExtraFlag2>
                <Paragraph>
                    Mit dem richtigen Verhalten und Schutzmaßnahmen kann das eigene Hautkrebsrisiko gesenkt werden.
                    Hilfreich dabei: Der UV-Index. Ein internationales Maß zur Einschätzung der Sonnenbrandgefahr.
                    Einfach aufgebaut wie eine Ampel gibt der UV-Index tagesaktuell Empfehlungen für den passenden
                    Sonnenschutz. Die Partner von WATCH OUT AT THE BEACH wollen den UV-Index an Orten der Freizeit
                    bekannt machen. Mit UV-Index-Tafeln und Informationsmaterial weisen wir die Menschen auf richtiges
                    UV-Schutz-Verhalten hin. Für eine gesunde Freizeitgestaltung.
                </Paragraph>
                <Paragraph>
                    WATCH OUT AT THE BEACH wird von der Arbeitsgemeinschaft Dermatologischen Prävention e.V. (ADP)
                    koordiniert und zum Großteil von der Deutschen Krebshilfe kofinanziert. Unsere Sponsoren und Partner
                    tragen ebenfalls einen wichtigen Teil dazu bei. Sie möchten in unserem Projekt aktiv werden? Senden
                    Sie und eine E-Mail mit dem Betreff WATCH OUT AT THE BEACH an: <a href='mailto:info@adpev.de'>info@adpev.de</a>
                </Paragraph>
                <H1>DER UV-INDEX</H1>
                <Paragraph>
                    Die UV-Strahlung der Sonne wird von vielen Faktoren beeinflusst und deshalb oft falsch eingeschätzt.
                    Ein praktisches Hilfsmittel dafür ist der UV-Index (UVI). Dabei handelt es sich um ein international
                    gültiges Maß, das die Stärke der UV-Strahlung und ihre Wirkung auf die Haut als Skalenwert
                    beschreibt. Der UV-Index funktioniert wie eine Ampel und zeigt an, wann welcher Sonnenschutz
                    notwendig ist. Er ist über viele Wetterapps und Wetterdienste abrufbar.
                </Paragraph>
                <Paragraph>
                    Der UV-Index wird oft als Tagesspitzenwert angezeigt und gibt damit den höchsten zu erwartenden Wert
                    an. Danach richten sich auch die angezeigten Schutzmaßnahmen. Unter dem Menüpunkt UV-Index:
                    Aktueller Wert kann der UV-Index auch über den Tag hinweg verfolgt werden.
                </Paragraph>
                <H1>IHRE MEINUNG</H1>
                <Paragraph>
                    Wir wollen mit WATCH OUT AT THE BEACH AT THE BEACH den Aufenthalt am Wasser sicherer gestalten.
                    Damit uns das möglichst gut gelingt, brauchen wir Ihre Meinung.
                </Paragraph>
                <CenterContainerEva>
                    <LinkAsButton href='https://www.surveymonkey.de/r/ZNQPT9J' target='blank'>
                        BITTE BEWERTEN SIE{'\n'}UNSERE AKTION
                    </LinkAsButton>
                </CenterContainerEva>
                <ArrowToTop />
            </ContentContainer>
        </>
    );
}