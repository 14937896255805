import { useState } from 'react';
import { FederalState, Location } from '../../assets/ts/customTypes';
import { Ul } from '../../assets/styles/generalStyles';
import { LocationLi, LocationFederalState } from '../../assets/styles/locationsStyles';

export function CollapsableLocationList({
    federalState,
    currentLocation,
    uvColor,
    handleSelection,
}: {
    federalState: FederalState;
    currentLocation: Location;
    uvColor: string;
    handleSelection: (event: React.MouseEvent<HTMLLIElement>) => void;
}) {
    const [open, setOpen] = useState(true);
    return (
        <>
            <LocationFederalState onClick={() => setOpen(!open)} key={federalState.name}>
                {federalState.name}
            </LocationFederalState>
            {open && <Ul>
                {federalState.locations.map((location: Location) => {
                    return (
                        <LocationLi
                            style={currentLocation.name === location.name ? { color: uvColor } : {}}
                            onClick={handleSelection}
                            key={location.lat + '/' + location.long}
                            id={location.name}
                        >
                            {location.name}
                        </LocationLi>
                    );
                })}
            </Ul>}
        </>
    );
}
