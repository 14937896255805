import { useEffect, useRef, useState } from 'react';
import { Menu } from './lib/components/Menu';
import { PageHealthyTan } from './pages/PageHealthyTan';
import { Landingpage } from './pages/Landingpage';
import { PageCampaign } from './pages/PageCampaign';
import { PageTips } from './pages/PageTips';
import { PageUVindex } from './pages/PageUVindex';
import { PageImprint } from './pages/PageImprint';
import { PageDetails } from './pages/PageDetails';
import { TopCover } from './assets/styles/menuStyles';
import { Page, PageNames, Location } from './assets/ts/customTypes';
import { useLocation } from './lib/context/LocationContext';
import { PageLocations } from './pages/PageLocations';
import { federalStates } from './assets/ts/locations';
import { PageSponsors } from './pages/PageSponsors';
import { CoordsPopUp } from './assets/styles/generalStyles';

export function App() {
    const [uvIndex, setUvIndex] = useState<number>();
    const [currentPage, setCurrentPage] = useState<Page>({ name: PageNames.LANDING, page: <Landingpage /> });
    const [secondsToContentPage, setSecondsToContentPage] = useState(4);
    const [needTopCover, setNeedTopCover] = useState(false);
    const [wasFadeTriggered, setWasFadeTriggered] = useState(false);
    const [isTimerGo, setIsTimerGo] = useState(false);
    const [locationOrFalse, setLocationOrFalse] = useState<[lat: number, long: number] | false>(false);
    const [currentLocation, setCurrentLocation] = useLocation();
    const [coordsVisible, setCoordsVisible] = useState(false);
    const FadeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {if (secondsToContentPage === 0) setNextPage(PageNames.INDEX);}, [currentLocation]);

    useEffect(() => {
        const lowerCaseUrl = window.location.pathname.toLowerCase();
        if (lowerCaseUrl === '/qr/liste') {
            transitionToContent({ locationType: 'city' });
            setSecondsToContentPage(0);
            return;
        }
        else if (lowerCaseUrl.includes('standort')) {
            const locationNameByUrl = window.location.pathname.slice(10);
            let allLocations: Location[] = [];
            federalStates.forEach((federalState) => {
                allLocations = allLocations.concat(federalState.locations);
            });
            const locationByUrl = federalStates[0].locations.find((location) => location.name === getFormatedLocationName(locationNameByUrl));
            if (locationByUrl) {
                setLocationOrFalse([locationByUrl.lat, locationByUrl.long]);
                setIsTimerGo(true);
                setCurrentLocation(locationByUrl);
                return;
            }
        }
        if (navigator.geolocation)
            navigator.geolocation.getCurrentPosition(
                (position) => {setLocationOrFalse([position.coords.latitude, position.coords.longitude]); setIsTimerGo(true)},
                (error) => { setLocationOrFalse(false);  setIsTimerGo(true)},
            );
    }, []);

    useEffect(() => {
        (async () => {
            fetch('/track.php', {
                credentials: 'same-origin',
            });
            if (locationOrFalse !== false) {
                const indexRes = await fetch('/get-uv-index.php', {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        lat: locationOrFalse[0],
                        long: locationOrFalse[1],
                        name: 'live-position',
                    }),
                });
                if (indexRes.ok) {
                    const indexJson: { ok: boolean; index: number; name: string } = await indexRes.json();
                    if (indexJson.ok) {
                        handleSettingUvValue(indexJson.index);
                    }
                } else {
                    setLocationOrFalse(false);
                }
            }
        })();
    }, [locationOrFalse]);

    useEffect(() => {
        let resetInterval = setInterval(() => {
            if (secondsToContentPage > 0 && isTimerGo) {
                setSecondsToContentPage(secondsToContentPage - 1);
            }
            if (secondsToContentPage <= 2 && !wasFadeTriggered && locationOrFalse !== false) {
                transitionToContent({ locationType: 'live' });
            } else if (secondsToContentPage <= 2 && !wasFadeTriggered && locationOrFalse === false) {
                transitionToContent({ locationType: 'city' });
            }
        }, 1000);
        return () => {
            clearInterval(resetInterval);
        };
    }, [secondsToContentPage, isTimerGo]);

    function transitionToContent({ locationType }: { locationType: 'live' | 'city' }) {
        if (locationType === 'live' && uvIndex !== undefined)
            setCurrentPage({
                name: PageNames.INDEX,
                page: (
                    <PageUVindex
                        setNextPage={setNextPage}
                        isNavigationInstant={true}
                        uvIndex={uvIndex}
                        showCoords={showCoords}
                    />
                ),
            });
        else {
            setCurrentPage({
                name: PageNames.LOCATIONS,
                page: <PageLocations uvIndex={uvIndex} setUvIndex={handleSettingUvValue} />,
            });
        }
        triggerFade();
    }

    function setNextPage(nextPage: PageNames) {
        switch (nextPage) {
            case PageNames.INDEX:
                setNeedTopCover(false);
                if (currentPage.name === PageNames.INDEX) {
                    document.getElementById('UV-' + Math.round(uvIndex ? uvIndex : 0))?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                } else {
                    setCurrentPage({
                        name: PageNames.INDEX,
                        page: (
                            <PageUVindex
                                setNextPage={setNextPage}
                                isNavigationInstant={true}
                                uvIndex={uvIndex ? uvIndex : 0}
                                showCoords={showCoords}
                            />
                        ),
                    });
                }
                break;
            case PageNames.TAN:
                setCurrentPage({ name: PageNames.TAN, page: <PageHealthyTan /> });
                setNeedTopCover(true);
                break;
            case PageNames.CAMPAIGN:
                setCurrentPage({ name: PageNames.CAMPAIGN, page: <PageCampaign /> });
                setNeedTopCover(true);
                break;
            case PageNames.TIPS:
                setCurrentPage({ name: PageNames.TIPS, page: <PageTips /> });
                setNeedTopCover(true);
                break;
            case PageNames.IMPRINT:
                setCurrentPage({ name: PageNames.IMPRINT, page: <PageImprint /> });
                setNeedTopCover(true);
                break;
            case PageNames.LOCATIONS:
                setCurrentPage({
                    name: PageNames.LOCATIONS,
                    page: <PageLocations uvIndex={uvIndex} setUvIndex={handleSettingUvValue} />,
                });
                setNeedTopCover(true);
                break;
            case PageNames.DETAILS:
                setCurrentPage({ name: PageNames.DETAILS, page: <PageDetails />, });
                break;
            case PageNames.SPONSORS:
                setCurrentPage({ name: PageNames.SPONSORS, page: <PageSponsors />, });
                break;
            default:
                setSecondsToContentPage(3);
                setCurrentPage({ name: PageNames.LANDING, page: <Landingpage /> });
                break;
        }
    }
    function triggerFade() {
        if (FadeRef.current && !wasFadeTriggered) {
            FadeRef.current.className = 'fadeInAnimation';
        }
        setWasFadeTriggered(true);
    }

    function getFormatedLocationName(urlLocation: string) {
        const formatedLocation = urlLocation.charAt(0).toUpperCase() + urlLocation.slice(1).toLowerCase();
        const decodedLocation = formatedLocation.replace('ue','ü').replace('oe','ö');
        return decodedLocation;
    }

    function handleSettingUvValue(newUvIndex: number) {
        setUvIndex(newUvIndex < 1 ? 1 : newUvIndex);
    }

    function showCoords() {
        setCoordsVisible(true);

        setTimeout(() => {
            setCoordsVisible(false);
        }, 5000);
    }

    return (
        <>
            {secondsToContentPage !== 0 && <Landingpage />}
            <div style={{ opacity: 0 }} ref={FadeRef}>
                {needTopCover && <TopCover />}
                <Menu setNextPage={setNextPage} />
                {currentPage.page}
                <CoordsPopUp
                    onClick={(e) => navigator.clipboard.writeText(e.currentTarget.innerHTML)}
                    coordsVisible={coordsVisible}
                >
                    {locationOrFalse ? `${locationOrFalse[0].toFixed(4)} ${locationOrFalse[1].toFixed(4)}` : (currentLocation.lat + " " + currentLocation.long)}
                </CoordsPopUp>
            </div>
        </>
    );
}
