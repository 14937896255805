import {
    LandingpageH1,
    LandingpageH1Margin,
    LandingpageContainer,
    LandingpageSubtitle,
    LandingpageTextContainer,
    LandingpageTitle,
} from '../assets/styles/landingpageStyles';
import { TheSun } from '../lib/components/TheSun';

export function Landingpage() {
    return (
        <LandingpageContainer>
            <TheSun isInText={true} onLandingpage={true} />
            <LandingpageTextContainer>
                <LandingpageTitle>
                    <LandingpageH1>WATCH</LandingpageH1>
                    <LandingpageH1>OUT</LandingpageH1>
                    <LandingpageH1>AT THE</LandingpageH1>
                    <LandingpageH1Margin>BEACH</LandingpageH1Margin>
                </LandingpageTitle>
                <LandingpageSubtitle>FÜR MEHR SONNENSCHUTZ AN ORTEN DER FREIZEIT</LandingpageSubtitle>
            </LandingpageTextContainer>
        </LandingpageContainer>
    );
}