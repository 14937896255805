import { ContentContainer, H1, Link, Paragraph, LinkAsButton, Ul } from '../assets/styles/generalStyles';
import { DetailsLinkContainer, DetailsBtnContainer } from '../assets/styles/detailsStyles';
import { TheSun } from '../lib/components/TheSun';
import { ArrowToTop } from '../lib/components/ArrowToTop';

export function PageDetails() {
    return (
        <>
            <TheSun isInText={false} bottom='45%' />
            <ContentContainer>
                <H1>UV INDEX LIVE</H1>
                <Paragraph>
                    Der UV-Index hilft bei der Einschätzung der UV-Strahlung. Über die Datenbank des Deutschen
                    Wetterdienstes (DWD) kann er für ganz Deutschland abgerufen werden. Die Werte sind modelliert.
                </Paragraph>
                <Paragraph>
                    Daneben betreibt das Bundesamt für Strahlenschutz (BfS) gemeinsam mit weiteren Institutionen das
                    UV-Messnetz. An über 40 Orten in Deutschland messen spezielle Messnetzstationen den UV-Index am
                    Erdboden. Hier kann der UV-Index über den Tag hinweg verfolgt werden.
                </Paragraph>
                <Ul>
                    Das verrät die UV-Index-Kurve:
                    <li>
                        Ab einem UV-Index von 3 schädigt die UV-Strahlung die Haut. Es ist Schutz erforderlich. Dieser
                        Wert wird oft schon am späten Vormittag erreicht.
                    </li>
                    <li>
                        Die Kurve zeigt deutlich, wie effektiv es ist, die UV-intensive Zeit drinnen oder im Schatten zu
                        verbringen. Der UV-Index ist in der Mittagszeit oft besonders hoch.
                    </li>
                    <li>
                        Während die Temperatur am Nachmittag oft noch hoch ist und sich die Wärme anstaut, kann der
                        UV-Index schon wieder langsam abfallen. Es lohnt sich immer, neben der Temperatur auch den
                        UV-Index zu beobachten.
                    </li>
                </Ul>
                <Paragraph>
                    Der tagesaktuelle Verlauf des UV-Index für unsere Partnerorte kann hier abgerufen werden.
                </Paragraph>
                <DetailsLinkContainer>
                    <Link href='https://uvi.bfs.de/Tagesgrafiken/EEr_Chieming_today.png' target='blank'>
                        BfS-Station in Chieming
                    </Link>
                    <Link href='https://uvi.bfs.de/Tagesgrafiken/EEr_Eckernfoerde_today.png' target='blank'>
                        BfS-Station in Eckernförde
                    </Link>
                    <Link href='https://uvi.bfs.de/Tagesgrafiken/EEr_Groemitz_today.png' target='blank'>
                        BfS-Station in Grömitz
                    </Link>
                    <Link href='https://uvi.bfs.de/Tagesgrafiken/EEr_Sylt_Tinnum_today.png' target='blank'>
                        BfS-Station in Sylt
                    </Link>
                </DetailsLinkContainer>
                <DetailsBtnContainer>
                    <Paragraph>
                        Mehr Informationen zum UV-Messnetz des Bundesamts für Strahlenschutz (BfS) gibt es hier:
                    </Paragraph>
                    <LinkAsButton
                        target='blank'
                        href='https://www.bfs.de/DE/themen/opt/uv/uv-index/uv-messnetz/uv-messnetz_node.html#:~:text=Das%20UV%20%2DMessnetz%20ist%20ein,die%20erdbodennahe%20UV%20%2DStrahlung%20ermitteln.'
                    >
                        Zum BfS
                    </LinkAsButton>
                </DetailsBtnContainer>
            </ContentContainer>
            <ArrowToTop />
        </>
    );
}