import styled from 'styled-components';
import { ContentContainer, H1, H2, Paragraph } from './generalStyles';

export const LocationH1 = styled(H1)`
    margin: 0.2rem 0;
`;
export const LocationLi = styled.li`
    cursor: pointer;
    font-size: 1.2rem;
    margin: 1.2rem 0;
`;
export const LocationP = styled(Paragraph)`
    font-size: 1.2rem;
`;
export const LocationContainer = styled(ContentContainer)`
    background-color: #000;
`;
export const LocationUl = styled.ul`
    list-style-type: none;
    padding-inline-start: 20px;

    & li:before {
        content: '- ';
    }
`;
export const LocationFederalState = styled(H2)`
    font-size: 2rem;
    cursor: pointer;
    position: relative;
    z-index: 2;
`;